import { createSlice } from '@reduxjs/toolkit'

export const sessionSlice = createSlice({
    name: 'auth/session',
    initialState: {
        token: '',
        refreshToken: '',
        signedIn: false,
    },
    reducers: {
        onSignInSuccess: (state, action) => {
            state.signedIn = true
            state.token = action.payload
            console.log('token set', action.payload)
        },
        onSignOutSuccess: (state) => {
            state.signedIn = false
            state.token = ''
            state.refreshToken = ''
        },
        setRefreshToken: (state, action) => {
            state.refreshToken = action.payload
        },
        onSetForgotPasswordToken: (state, action) => {
            state.token = action.payload
        },
    },
})

export const {
    onSignInSuccess,
    onSignOutSuccess,
    setRefreshToken,
    onSetForgotPasswordToken,
} = sessionSlice.actions

export default sessionSlice.reducer
