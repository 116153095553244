import appConfig from 'configs/app.config'
import ApiService from './ApiService'
import {
    REQUEST_HEADER_AUTH_KEY,
    TOKEN_TYPE,
    endpoints,
} from 'constants/api.constant'
import { onSignInSuccess, onSignOutSuccess } from 'store/auth/sessionSlice'
import axios from 'axios'
import { setUser, setHotelInfo, setMessage } from 'store/auth/userSlice'
import store from '../store'
import { toast, Notification } from 'components/ui'
import { useSelector } from 'react-redux'

export async function apiSignIn(data) {
    return ApiService.fetchData({
        url: appConfig.baseUrl + endpoints.LOGIN,
        method: 'post',
        data,
    })
}

export const apiRefreshToken = async () => {
    try {
        const { auth } = store.getState()
        const refreshToken = auth.session.refreshToken
        const headers = {}
        headers[REQUEST_HEADER_AUTH_KEY] = `${TOKEN_TYPE}${refreshToken}`
        // Make an API request to refresh the token
        const response = await axios.post(
            appConfig.baseUrl + endpoints.REFRESH_TOKEN,
            null,
            { headers }
        )

        const newAccessToken = response.data.access_token
        // Update the token in your application state
        store.dispatch(onSignInSuccess(newAccessToken))
        store.dispatch(setUser(response.data.user))
        store.dispatch(setHotelInfo(response.data.hotel_info))
        store.dispatch(setMessage(response.data.message))
        return newAccessToken
    } catch (error) {
        store.dispatch(onSignOutSuccess())
        setTimeout(() => {
            toast.push(
                <Notification closable type="danger" duration={3000}>
                    {error.response.data.message}
                </Notification>
            )
        }, 1)
        throw error
    }
}
export async function apiStoreFirebaseToken(data) {
    return ApiService.fetchData({
        url: appConfig.baseUrl + '/admin/api/auth/set-firebase-token',
        method: 'post',
        data,
    })
}

export async function apiSignUp(data) {
    return ApiService.fetchData({
        url: '/sign-up',
        method: 'post',
        data,
    })
}

export async function apiSignOut(data) {
    return ApiService.fetchData({
        url: '/sign-out',
        method: 'post',
        data,
    })
}

export async function apiForgotPassword(data) {
    return ApiService.fetchData({
        url: appConfig.baseUrl + '/admin/api/auth/forgot-password',
        method: 'post',
        data,
    })
}
export async function apiVerifyOTP(data) {
    return ApiService.fetchData({
        url: appConfig.baseUrl + '/admin/api/auth/verify-otp',
        method: 'post',
        data,
    })
}
export const apiSetNewPassword = async (data) => {
    return ApiService.fetchData({
        url: appConfig.baseUrl + '/admin/api/auth/reset-password',
        method: 'post',
        data,
    })
}
export async function apiResetPassword(data) {
    return ApiService.fetchData({
        url: '/reset-password',
        method: 'post',
        data,
    })
}
export async function apiChangePassword(data) {
    console.log('pass', data)
    return ApiService.fetchData({
        url: '/admin/api/auth/change-password',
        method: 'post',
        data,
    })
}
