import { toast, Notification } from 'components/ui'

function showToast({ type = 'success', message, title }) {
    toast.push(
        <Notification title={title} type={type} duration={2500}>
            {message}
        </Notification>,
        {
            placement: 'top-center',
        }
    )
}

export { showToast }
