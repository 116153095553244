import { useSelector, useDispatch } from 'react-redux'
import {
    setUser,
    setHotelInfo,
    setMessage,
    initialState,
} from 'store/auth/userSlice'
import {
    apiSignIn,
    apiSignOut,
    apiSignUp,
    apiStoreFirebaseToken,
} from 'services/AuthService'
import {
    onSignInSuccess,
    onSignOutSuccess,
    setRefreshToken,
} from 'store/auth/sessionSlice'
import appConfig from 'configs/app.config'
import { REDIRECT_URL_KEY } from 'constants/app.constant'
import { useNavigate } from 'react-router-dom'
import useQuery from './useQuery'
import { getFirebaseToken } from 'firebase.js'

function useAuth() {
    const dispatch = useDispatch()

    const navigate = useNavigate()

    const query = useQuery()

    const { token, signedIn } = useSelector((state) => state.auth.session)

    const signIn = async (values) => {
        try {
            const resp = await apiSignIn(values)
            if (resp.data) {
                const token = resp.data.access_token
                dispatch(onSignInSuccess(token))
                const refreshToken = resp.data.refresh_token
                dispatch(setRefreshToken(refreshToken))

                if (resp.data) {
                    dispatch(
                        setUser(
                            resp.data.user || {
                                avatar: '',
                                userName: 'Anonymous',
                                authority: ['USER'],
                                email: '',
                            }
                        )
                    )

                    dispatch(setHotelInfo(resp.data.hotel_info))
                    dispatch(setMessage(resp.data.message))
                }

                var firebaseToken = await getFirebaseToken()
                const tokenResp = await apiStoreFirebaseToken({
                    firebase_token: firebaseToken,
                })

                console.log('token', firebaseToken)
                return {
                    status: 'success',
                    message: '',
                }
            }
        } catch (errors) {
            return {
                status: 'failed',
                message: errors?.response?.data?.message || errors.toString(),
            }
        }
    }

    const signUp = async (values) => {
        try {
            const resp = await apiSignUp(values)
            if (resp.data) {
                const { token } = resp.data
                dispatch(onSignInSuccess(token))
                if (resp.data.user) {
                    dispatch(
                        setUser(
                            resp.data.user || {
                                avatar: '',
                                userName: 'Anonymous',
                                authority: ['USER'],
                                email: '',
                            }
                        )
                    )
                }
                // const redirectUrl = query.get(REDIRECT_URL_KEY)
                // navigate(
                //     redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath
                // )
                return {
                    status: 'success',
                    message: '',
                }
            }
        } catch (errors) {
            return {
                status: 'failed',
                message: errors?.response?.data?.message || errors.toString(),
            }
        }
    }

    const handleSignOut = () => {
        dispatch(onSignOutSuccess())
        dispatch(setUser(initialState))
        navigate(appConfig.unAuthenticatedEntryPath)
    }

    const signOut = async () => {
        //await apiSignOut()
        handleSignOut()
    }

    return {
        authenticated: token && signedIn,
        signIn,
        signUp,
        signOut,
    }
}

export default useAuth
