import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'
import { getMessaging, getToken, onMessage } from 'firebase/messaging'
import { showToast } from 'utils/toast'

const firebaseConfig = {
    apiKey: 'AIzaSyBSkuz1ncK6PHAsc0wZBTAG-7Kizch3EwY',
    authDomain: 'hotel360-live.firebaseapp.com',
    projectId: 'hotel360-live',
    storageBucket: 'hotel360-live.appspot.com',
    messagingSenderId: '113842576465',
    appId: '1:113842576465:web:60b49bdca7797248b86c70',
    measurementId: 'G-TEJ5MZWSPW',
}

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig)
const analytics = getAnalytics(firebaseApp)

const messaging = getMessaging(firebaseApp)

export const getFirebaseToken = async () => {
    return await getToken(messaging, {
        vapidKey:
            'BGiNCDGgMJV-Qw8UVjxh8Oyk4LXPkBmSXIiyFPhj5oofQAe3BmHQDgfOZwWGcFWy9URRDybTuptngqQJQlp8MsE',
    })
}

onMessage(messaging, (payload) => {
    console.log('Message received. ', payload)
    // showToast({
    //     type: 'success',
    //     title: payload.notification.title,
    //     message: payload.notification.body,
    // })
    // new Notification()
    // ...
    const notificationTitle = payload.notification.title
    const notificationOptions = {
        body: payload.notification.body,
        icon: './img/logo/logo-light-streamline.png',
    }

    new Notification(notificationTitle, notificationOptions)
})
