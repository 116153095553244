export const APP_NAME = 'Hotel360'
export const APP_VERSION = '1.5.7'
export const PERSIST_STORE_NAME = 'admin'
export const REDIRECT_URL_KEY = 'redirectUrl'

export const CUSTOMISABLE_TOUR = 'customisable'
export const NON_CUSTOMISABLE_TOUR = 'non-customisable'
export const GROUP_TOUR = 'group'
export const identityTypes = {
    aadhaar: 'Aadhar',

    driving_license: 'Driving License',

    voter_id: 'Voter ID',

    passport: 'Passport',
}
export const paymentTypes = {
    advance: 'Advance',
    partial: 'Partial',
    final: 'Final',
}
export const MINIMUM_PAGESIZE = 10 // Minimum number of items per page for pagination in the DataTable
export const BOOKINGENGINE_HOTELCODE = process.env.REACT_APP_BOOKING_ENGINE_URL
