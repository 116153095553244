const appConfig = {
    // apiPrefix: '/api',
    authenticatedEntryPath: '/rooms-overview',
    unAuthenticatedEntryPath: '/sign-in',
    tourPath: '/',
    locale: 'en',
    enableMock: false,
    // baseUrl: 'https://gt-dev.wizi.technology',
    //     'https://container-service-1.cm45dl802areg.ap-south-1.cs.amazonlightsail.com',
    imageBaseUrl: process.env.REACT_APP_S3_URL,
    // imageAccessKey:'AKIAU2PEKTN76VGWMYOF',
    // imageSecretKey:'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',
    // imageRegion:'ap-south-1',
    // imageBucketName:'gt-holidays'
    // baseUrl:
    //     'https://container-service-1.cm45dl802areg.ap-south-1.cs.amazonlightsail.com',
    baseUrl: process.env.REACT_APP_BASE_URL,
}

export default appConfig
