import appConfig from 'configs/app.config'
import { TOKEN_TYPE, REQUEST_HEADER_AUTH_KEY } from 'constants/api.constant'
import { PERSIST_STORE_NAME } from 'constants/app.constant'
import deepParseJson from 'utils/deepParseJson'
import store from '../store'
import { onSignOutSuccess } from '../store/auth/sessionSlice'
import axios from 'axios'
import { apiRefreshToken } from './AuthService'

const BaseService = axios.create({
    timeout: 60000,
    baseURL: appConfig.baseUrl,
})

BaseService.interceptors.request.use(
    (config) => {
        const rawPersistData = localStorage.getItem(PERSIST_STORE_NAME)
        const persistData = deepParseJson(rawPersistData)
        const { auth } = store.getState()
        let accessToken = auth.session.token

        if (!accessToken) {
            accessToken = persistData.auth.session.token
        }

        if (accessToken) {
            config.headers[
                REQUEST_HEADER_AUTH_KEY
            ] = `${TOKEN_TYPE}${accessToken}`
        }

        return config
    },
    (error) => {
        return Promise.reject(error)
    }
)
const unauthorizedCode = [401]
BaseService.interceptors.response.use(
    (response) => response,
    async (error) => {
        const { response } = error
        const originalRequest = error.config
        if (error.response.status === 498 && !originalRequest._retry) {
            //token is expired
            originalRequest._retry = true
            await apiRefreshToken()
            console.log('calling original api again..')
            return BaseService(originalRequest)
        }

        if (response && unauthorizedCode.includes(response.status)) {
            store.dispatch(onSignOutSuccess())
        }
        console.log('here', error.response)
        if (
            error.response.data == null ||
            error.response.data.message == null
        ) {
            return Promise.reject('Some error occured [101]')
        }

        return Promise.reject(error.response.data.message)
    }
)

const ApiService = {
    async fetchData(param) {
        return await BaseService(param)
        // return new Promise((resolve, reject) => {
        //     BaseService(param)
        //         .then((response) => {
        //             resolve(response)
        //         })
        //         .catch((errors) => {
        //             reject(errors)
        //         })
        // })
    },
}
export default ApiService
