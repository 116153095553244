import React from 'react'
import PropTypes from 'prop-types'
import useAuthority from 'utils/hooks/useAuthority'
import { checkPermission } from 'utils/check_permission'

const AuthorityCheck = (props) => {
    const { userAuthority = [], authority = [], children } = props
    var canPermit = true
    if (authority.length > 0) {
        canPermit = authority.some((value) => checkPermission(value))
    }
    return canPermit ? children : <></>
}

AuthorityCheck.propTypes = {
    userAuthority: PropTypes.array,
    authority: PropTypes.array,
}

export default AuthorityCheck
